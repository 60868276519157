// Use when we start ad campaign and have UTM trackers in CRM
if (typeof window !== "undefined" && typeof document !== "undefined") {
  // Check for URL params
  if (window.location.search) {
    var paramsArray = window.location.search.replace("?", "").split("&");
    var paramsMap = {};

    // Create array for URL params
    for (const paramString of paramsArray) {
      const paramName = paramString.substring(0, paramString.indexOf("="));
      const paramValue = paramString.substring(paramString.indexOf("=") + 1);
      paramsMap[paramName] = paramValue;
    }

    // Check for existing UTM params
    if (
      paramsMap.utm_campaign ||
      paramsMap.utm_medium ||
      paramsMap.utm_source ||
      paramsMap.utm_content ||
      paramsMap.utm_term ||
      paramsMap.gclid ||
      paramsMap.msclid
    ) {
      removeUTMValues();
    } else {
      setUTMValues();
    }
  }
}

function removeUTMValues() {
  window.localStorage.removeItem("utm_campaign");
  window.localStorage.removeItem("utm_medium");
  window.localStorage.removeItem("utm_source");
  window.localStorage.removeItem("utm_content");
  window.localStorage.removeItem("utm_term");
  window.localStorage.removeItem("gclid");
  window.localStorage.removeItem("msclid");

  setUTMValues();
}

function setUTMValues() {
  // Add UTM params to local storage
  paramsMap.utm_campaign &&
    window.localStorage.setItem(
      "utm_campaign",
      decodeURIComponent(paramsMap.utm_campaign)
    );
  paramsMap.utm_medium &&
    window.localStorage.setItem(
      "utm_medium",
      decodeURIComponent(paramsMap.utm_medium)
    );
  paramsMap.utm_source &&
    window.localStorage.setItem(
      "utm_source",
      decodeURIComponent(paramsMap.utm_source)
    );
  paramsMap.utm_content &&
    window.localStorage.setItem(
      "utm_content",
      decodeURIComponent(paramsMap.utm_content)
    );
  paramsMap.utm_term &&
    window.localStorage.setItem(
      "utm_term",
      decodeURIComponent(paramsMap.utm_term)
    );
  paramsMap.gclid && window.localStorage.setItem("gclid", paramsMap.gclid);
  paramsMap.msclid && window.localStorage.setItem("msclid", paramsMap.msclid);
}
